*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}


html {
    font-size: 62.5%;
}

body {
  box-sizing: border-box;
//   padding: 3rem;
font-family: 'objektiv-mk3', sans-serif;
font-weight: 500;
letter-spacing: 2px;
line-height: 4rem;
height: 100vh;
}

/* 
Skolar Sans Latin Condensed Light 
Skolar Sans Latin Extended Bold 
Avance Pro Regular
Facit Regular 
Objektiv Mk3 Regular 
Justus Pro Regular 
RePublic Regular

*/