// https://colorpalette.org/urban-area-sky-city-color-palette/

$sienna: #a8462e;
$roseebony: #613b34;
$wheat: #f1d9bb;
$peru: #d08346;
$rosybrown: #bd8c66;
$darkslategray-1: #2c3840;
$darkslategray-2: #1f2935;
$darkslategray-3: #334756;
$darkslategray-4: #3c5867;
$dimgray: #6c6b6a;
$ivory: #fbfaeb;
$oldburgundy: #34201b;

$outrageousorange: #fa7e61;
$dodgerblue: #1789fc;
$darkgreen: #002500;
$forestgreentraditional: #0a3200;
$redpigment: #f13030;
$bluecrayola-1: #2176ff;
$bluecrayola-2: #3772ff;
$marvelous: #f59ca9;
$redRYB: #ff3a20;
$bluepigment: #29339b;

$amber-sae-ace: #ff7f11ff;

$marigold: #f3a712;
$purered: #fd151b;
$bluepantone: #0d21a1;

$yelloworangecolorwheel: #f79824;
$pumpkin-1: #fe7f2d;
$pumpkin-2: #fc7a1e;

// colors pass:
$dimgray: #6c6b6a;
$ivory: #fbfaeb;
$oldburgundy: #34201b;
$roseebony: #613b34;
$amber-sae-ace: #ff7f11ff;
$marigold: #f3a712;
$purered: #fd151b;
$navyblue: #150578;
$bluepantone: #0d21a1;
$darkskyblue: #a0c0cd;
$deepspacesprakle: #3c5867;
$charcoal: #334756;
$gunmetal: #1f2935;
$gunmetal-2: #2c3840;

// color pass 2
$dimgray: #6c6b6a;
$ivory: #fbfaeb;
$oldburgundy: #34201b;
$marigold: #f3a712;
$marigold-2: #f1a208;
$blackcoffee: #32292f;
$blackfogra39: #0a0f0d;
$blackfogra29: #001427;
$darkjunglegreen: #041f1e;
$charlestongreen: #1e2d2f;
$jet: #363537;
$emerald: #0cce6b;
$smokyblack: #12100e;
$raisinblack: #2d232e;
$darkliver: #474448;
$xiketic: #050517;
$eerieblack: #171614;
$darkjunglegreen-2: #141e1a;
$lightslategray: #7c90a0;
$blizzardblue: #a9e4ef;
$cadetgray: #829399;
$white: #fcfcfc;
$platinum: #e8e8e8ff;
$sunray: #e4b363;
$fireopal: #ef6461;
$electricblue: #87f6ff;
$ghostwhite: #f6f8ff;
$lemonyellow: #FAF33E;
$keylime: #EAF27C;
$lavenderbush: #F1E4E8;
$gainsboro: #E2DCDE;
$thistle: #CEB1BE;
/* background colors:
* darkjunglegreen
* backfogra29 */

/* 
textcolor:
darkliver,
cadetgray
*/

/* Header:
emerald,
marigold,
electricblue,
fire opal,
lightslategray
lavenderbush
*/

// background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
// background-image: linear-gradient(to right, #2e1620, #281826, #211b28, #1b1d28, #171e26);


// background-image: linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%);
// background-image: linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%);

//background-image: linear-gradient(to right top, #0cce6b, #00c56f, #00bc71, #00b373, #00aa74);
// background-image: linear-gradient(to right top, #87f6ff, #72e6ff, #67d5ff, #68c4fa, #72b1f2);
// background-image: linear-gradient(to right top, #829399, #7c9fa6, #75acaf, #6fb9b4, #6ec5b4);

// background-image: linear-gradient(to right top, #ef6461, #ea5b73, #e05685, #d05596, #bc58a4);
// background-image: linear-gradient(to right, #e4b363, #cdaf54, #b4aa49, #99a541, #7da03d);