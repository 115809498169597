.wrapper {
  width: 30%;
  display: inline-block;
  padding-bottom: 1em;
}

// .w5 .cube { perspective-origin: center; }

.cube {
  font-size: 2em;
  width: 2em;
  height: 2em;
  margin: 0.5em auto;
  transform-style: preserve-3d;
  perspective: 250px;
  perspective-origin: center;
}

.side {
  position: absolute;
  width: 2em;
  height: 2em;

  background: rgba(tomato, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.5);

  color: white;
  text-align: center;
  line-height: 2em;
}

.front {
  transform: translateZ(1em);
}
.top {
  transform: rotateX(90deg) translateZ(1em);
}
.right {
  transform: rotateY(90deg) translateZ(1em);
}
.left {
  transform: rotateY(-90deg) translateZ(1em);
}
.bottom {
  transform: rotateX(-90deg) translateZ(1em);
}
.back {
  transform: rotateY(-180deg) translateZ(1em);
}
